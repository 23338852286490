import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/react-hooks';

import { showGraphQLError } from '../../../helpers/utils';
import BULK_FIX_EMPLOYEE_HEALTH_CHECKS from '../mutations/BulkFixEmployeeHealthChecks';
import { useCompanyContext } from '../../../context/CompanyContext';
import { EmployeeHealthChecksAttributes } from '../../../../graphql';
import HEALTH_CHECK_STATUSES_QUERY from '../queries/HealthCheckStatusesQuery';

const useFixBulkEmployeeHealthChecks = (afterSave: () => void) => {
  const alert = useAlert();
  const { id: companyId } = useCompanyContext();

  const [fixHealthChecks, { loading: mutationLoading, called }] = useMutation(
    BULK_FIX_EMPLOYEE_HEALTH_CHECKS,
    {
      onCompleted() {
        afterSave();
      },
      onError: ({ graphQLErrors }) => {
        showGraphQLError(alert, graphQLErrors);
      },
      refetchQueries: [
        {
          query: HEALTH_CHECK_STATUSES_QUERY,
          variables: { companyId },
        },
      ],
    },
  );

  const onSave = async (employeeAttributes: EmployeeHealthChecksAttributes[]) =>
    fixHealthChecks({
      variables: {
        companyId,
        employeeAttributes,
      },
    });

  return { submitting: mutationLoading && called, onSave };
};

export default useFixBulkEmployeeHealthChecks;
