export const HEADER_HEIGHT = 40;
export const ROW_HEIGHT = 56;
export const MAX_ALLOWED_DECIMALS = 8;

export const ROW_STATES = {
  CLEAN: 'clean',
  ISSUES: 'issues',
};

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: ROW_STATES.CLEAN,
    value: ROW_STATES.CLEAN,
    label: 'Clean',
  },
  {
    key: ROW_STATES.ISSUES,
    value: ROW_STATES.ISSUES,
    label: 'Issues',
  },
];

export const DEFAULT_FILTER_VALUES = {
  searchText: '',
  state: FILTER_ITEMS[0].value,
  isFilterApplied: false,
};
