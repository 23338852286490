/* eslint-disable class-methods-use-this */
import * as React from 'react';

import {
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
  Cell,
  Compatible,
  TextCellTemplate as GridTextCellTemplate,
  CellTemplate,
  getCellProperty,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';
import EditModeCellWrap from './EditModeCellWrap';
import { sanitizeValue } from '../cell_utils';

// THIS FILE IS A extended from ORIGINAL FILE TextCellTemplate.tsx with our custom validation logic

export interface TextCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'text';
  text: string;
  filterText?: string;
  placeholder?: string;
  maxLength?: number;
  renderer?: (text: string) => React.ReactNode;
  isInvalidCell?: boolean;
  errorMessage?: string;
}

// @ts-ignore
class TextCellTemplate extends GridTextCellTemplate
  implements CellTemplate<TextCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(uncertainCell: Uncertain<TextCell>): Compatible<TextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    let columnId: string | undefined;
    let rowId: number | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells
    return { ...uncertainCell, text, columnId, rowId, value, placeholder };
  }

  update(
    cell: Compatible<TextCell>,
    cellToMerge: UncertainCompatible<TextCell>,
  ): Compatible<TextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: sanitizeValue(cellToMerge.text),
      placeholder: cellToMerge.placeholder,
    });
  }

  getClassName(cell: Compatible<TextCell>, _isInEditMode: boolean): string {
    const className = cell.className ? cell.className : '';
    const isMissingData = cell.text === '';
    const isValidClass = cell.isInvalidCell
      ? `rg-invalid ${isMissingData ? 'rg-invalid-missing-data' : ''}`
      : 'valid';
    return `${isValidClass} ${
      cell.placeholder && isMissingData ? 'placeholder' : ''
    } ${className}`;
  }

  render(
    cell: Compatible<TextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TextCell>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      const cellText = cell.text || cell.placeholder || '';
      const textToDisplay = cell.renderer ? cell.renderer(cellText) : cellText;
      return (
        <EditModeCellWrap
          isInvalidCell={cell.isInvalidCell}
          errorMessage={cell.errorMessage}
          cellValue={cell.text}
          cellId={`${cell.rowId}-${cell.columnId}`}
        >
          {textToDisplay}
        </EditModeCellWrap>
      );
    }

    return (
      <input
        className="rg-input"
        data-1p-ignore
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false,
          )
        }
        onBlur={(e) => {
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            !this.wasEscKeyPressed,
          );
          this.wasEscKeyPressed = false;
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        maxLength={cell.maxLength}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
      />
    );
  }
}

export default TextCellTemplate;
