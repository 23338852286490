import React, { useMemo, useState } from 'react';
import { Box, NullState } from '@angellist/adapt';
import {
  ReactGrid,
  CellChange,
  MenuOption,
  CellLocation,
  Id,
  SelectionMode,
} from '@silevis/reactgrid';
import '../styles/styles.scss';
import {
  ActiveDropdownType,
  DataEditorProps,
  GridColumnType,
  GridFilterValueType,
  GridRowType,
} from '../types';
import {
  getChangedRows,
  getDefaultEmptyRow,
  getHeaderRow,
  getFilteredRows,
  isGridRowTouched,
} from '../utils';
import useGridColumnsMetaData from '../hooks/useGridColumnsMetaData';
import DropdownCellTemplate from './DropdownCellTemplate';
import TextCellTemplate from './TextCellTemplate';
import NumberCellTemplate from './NumberCellTemplate';
import DateCellTemplate from './DateCellTemplate';
import HeaderCellTemplate from './HeaderCellTemplate';
import NonEditableCellTemplate from './NonEditableCellTemplate';
import MoneyInputCellTemplate from './MoneyInputCellTemplate';
import RowActionCellTemplate from './RowActionCellTemplate';
import useRowActionsMenuItems from '../hooks/useRowActionsMenuItems';

type Props = {
  gridFilters: GridFilterValueType;
} & DataEditorProps;

const DataEditor = (props: Props) => {
  const {
    data,
    setData,
    columns,
    hideContextMenu,
    enableFillHandle = true,
    enableRangeSelection = true,
    autoAppendRows = true,
    appendRowsCount = 10,
    showRowNumbers,
    showRowActions,
    getRowActionMenuItems,
    maxHeight,
    minHeight,
    gridFilters,
    isLoading,
    noDataProps,
  } = props;

  const [activeSelectData, setActiveSelectData] = useState<ActiveDropdownType>({
    rowId: -1,
    columnId: '',
  });
  const [cellChangesIndex, setCellChangesIndex] = useState(-1);
  const [cellChanges, setCellChanges] = useState<CellChange[][]>([]);

  const getActionMenuItems = useRowActionsMenuItems(
    getRowActionMenuItems,
    setData,
  );
  const showActionColumn = !!(showRowActions && getActionMenuItems);

  const tableColumns = useMemo(() => {
    const allColumns: GridColumnType[] = [];
    if (showRowNumbers) {
      allColumns.push({
        label: '#',
        text: '',
        key: '',
        type: 'rowNumber' as 'rowNumber',
        className: 'custom-row-number-cell',
        width: 80,
        isResizable: false,
      });
    }
    allColumns.push(...columns);
    if (showActionColumn) {
      allColumns.push({
        label: '',
        text: '',
        key: 'actions',
        type: 'rowAction' as 'rowAction',
        className: 'custom-row-action-cell',
        width: 80,
        isResizable: false,
      });
    }
    return allColumns;
  }, [isLoading]);

  const { containerRef, gridColumns, onColumnResize } = useGridColumnsMetaData(
    tableColumns,
  );

  // React state for rows
  const filteredRows = getFilteredRows(data, tableColumns, gridFilters, {
    activeSelectData,
    getRowActionMenuItems: getActionMenuItems,
  });

  const rows = [getHeaderRow(tableColumns), ...filteredRows];

  const applyChangesToData = (
    changes: CellChange[],
    prevData: GridRowType[],
  ): { changedRows: GridRowType[]; activeDropdown: ActiveDropdownType } => {
    const { changedRows, activeDropdown } = getChangedRows(prevData, changes);

    if (autoAppendRows && appendRowsCount) {
      const lastRow = changedRows[changedRows.length - 1];
      if (isGridRowTouched(lastRow)) {
        const defaultEmptyRow = getDefaultEmptyRow(columns);
        for (let i = 0; i < appendRowsCount; i += 1) {
          changedRows.push({ ...defaultEmptyRow });
        }
      }
    }

    return {
      changedRows,
      activeDropdown,
    };
  };

  const handleChanges = (changes: CellChange[]) => {
    setData((prevData) => {
      const { changedRows, activeDropdown } = applyChangesToData(
        changes,
        prevData,
      );
      setActiveSelectData(activeDropdown);
      setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
      setCellChangesIndex(cellChangesIndex + 1);
      return changedRows;
    });
  };

  const onContextMenu = (
    _selectedRowIds: Id[],
    _selectedColIds: Id[],
    _selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    _selectedRanges: Array<CellLocation[]>,
  ): MenuOption[] => menuOptions;

  const isMacOs =
    (navigator.platform?.toUpperCase()?.indexOf('MAC') || -1) >= 0;

  const handleUndoChanges = () => {
    if (cellChangesIndex >= 0) {
      setData((prevData) => {
        const changes = cellChanges[cellChangesIndex];
        const { changedRows, activeDropdown } = getChangedRows(
          prevData,
          changes,
          true,
        ); // Use previousCell instead of newCell
        setActiveSelectData(activeDropdown);
        setCellChangesIndex(cellChangesIndex - 1);
        return changedRows;
      });
    }
  };

  const handleRedoChanges = () => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      setData((prevData) => {
        const changes = cellChanges[cellChangesIndex + 1];
        const { changedRows, activeDropdown } = applyChangesToData(
          changes,
          prevData,
        );
        setActiveSelectData(activeDropdown);
        setCellChangesIndex(cellChangesIndex + 1);
        return changedRows;
      });
    }
  };

  return (
    <Box
      overflow="scroll"
      position="relative"
      ref={containerRef}
      onKeyDown={(e) => {
        if (
          (isMacOs && e.ctrlKey) ||
          e.ctrlKey ||
          e.metaKey ||
          (isMacOs && e.ctrlKey && e.shiftKey)
        ) {
          if (e.key === 'z') {
            handleUndoChanges();
          } else if (e.key === 'y') {
            handleRedoChanges();
          }
        }
      }}
      style={{ maxHeight, minHeight, overscrollBehaviorX: 'none' }}
    >
      <ReactGrid
        rows={rows}
        columns={gridColumns}
        onCellsChanged={handleChanges}
        stickyTopRows={1}
        stickyLeftColumns={showRowNumbers ? 1 : 0}
        stickyRightColumns={showActionColumn ? 1 : 0}
        enableFillHandle={enableFillHandle}
        enableRangeSelection={enableRangeSelection}
        onColumnResized={onColumnResize}
        customCellTemplates={{
          nonEditable: new NonEditableCellTemplate(),
          header: new HeaderCellTemplate(),
          dropdown: new DropdownCellTemplate(),
          text: new TextCellTemplate(),
          number: new NumberCellTemplate(),
          date: new DateCellTemplate(),
          money: new MoneyInputCellTemplate(),
          rowAction: new RowActionCellTemplate(),
        }}
        onContextMenu={hideContextMenu ? undefined : onContextMenu}
      />
      {data.length === 0 && (
        <Box padding="100" position="sticky" left="0">
          <NullState
            title={noDataProps?.title || 'No data available'}
            description={noDataProps?.description}
          />
        </Box>
      )}
      {!!data.length &&
        filteredRows.length === 0 &&
        gridFilters.isFilterApplied && (
          <Box padding="100" position="sticky" left="0">
            <NullState
              title="No matching results"
              description="We could not find any matching results. Clear filters to view all records."
            />
          </Box>
        )}
    </Box>
  );
};

export default DataEditor;
