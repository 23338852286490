import * as React from 'react';
import { FC } from 'react';
import Select, { OptionProps, MenuProps } from 'react-select';

import { getDropdownSelectedOption } from '../cell_utils';

export type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

const CustomOption: React.FC<OptionProps<OptionType, false>> = ({
  innerProps,
  label,
  isSelected,
  isFocused,
  isDisabled,
}) => (
  <div
    {...innerProps}
    onPointerDown={(e) => e.stopPropagation()}
    className={`rg-dropdown-option${isSelected ? ' selected' : ''}${
      isFocused ? ' focused' : ''
    }${isDisabled ? ' disabled' : ''}`}
  >
    {label}
  </div>
);

const CustomMenu: React.FC<MenuProps<OptionType, false>> = ({
  innerProps,
  children,
}: any) => (
  <div
    {...innerProps}
    className="rg-dropdown-menu"
    onPointerDown={(e) => e.stopPropagation()}
  >
    {children}
  </div>
);

const DropdownInput: FC<DIProps> = ({ onCellChanged, cell }) => {
  const selectRef = React.useRef<any>(null);

  const [inputValue, setInputValue] = React.useState<string | undefined>(
    cell.inputValue,
  );

  const selectedOption = React.useMemo<OptionType | undefined>(
    () => getDropdownSelectedOption(cell.text, cell.values),
    [cell.text, cell.values],
  );

  React.useEffect(() => {
    if (cell.isOpen && selectRef.current) {
      selectRef.current.focus();
      setInputValue(cell.inputValue);
    }
  }, [cell.isOpen, cell.inputValue]);

  return (
    <div
      style={{ width: '100%' }}
      onPointerDown={() => onCellChanged({ ...cell, isOpen: true })}
    >
      {/* @ts-ignore */}
      <Select
        {...(cell.inputValue && {
          inputValue,
          defaultInputValue: inputValue,
          onInputChange: (e) => setInputValue(e),
        })}
        key={`select-${cell.rowId}-${cell.columnId}-${selectedOption?.value ||
          'none'}`}
        isSearchable
        ref={selectRef}
        {...(cell.isOpen !== undefined && { menuIsOpen: cell.isOpen })}
        onMenuOpen={() => onCellChanged({ ...cell, isOpen: true })}
        onChange={(e) => {
          onCellChanged({
            ...cell,
            selectedValue: (e as OptionType).value,
            isOpen: false,
            inputValue: undefined,
          });
        }}
        placeholder="Select"
        blurInputOnSelect
        defaultValue={selectedOption}
        value={selectedOption}
        isDisabled={cell.isDisabled}
        options={cell.values}
        onKeyDown={(e: any) => {
          e.stopPropagation();

          if (e.key === 'Escape') {
            selectRef.current.blur();
            return onCellChanged({
              ...cell,
              isOpen: false,
              inputValue: undefined,
            });
          }

          return null;
        }}
        components={{
          Option: CustomOption,
          Menu: CustomMenu,
        }}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
            height: '100%',
            ...cell.styles?.container,
          }),
          control: (provided) => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            minHeight: '25px',
            background: 'transparent',
            boxShadow: 'none',
            ...cell.styles?.control,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            paddingTop: '0px',
            ...cell.styles?.indicatorsContainer,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: '0px 4px',
            ...cell.styles?.dropdownIndicator,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'inherit',
            ...cell.styles?.singleValue,
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            marginTop: '4px',
            marginBottom: '4px',
            display: 'none',
            ...cell.styles?.indicatorSeparator,
          }),
          input: (provided) => ({
            ...provided,
            padding: 0,
            ...cell.styles?.input,
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            ...cell.styles?.valueContainer,
          }),
        }}
      />
    </div>
  );
};

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
}

export default DropdownInput;
