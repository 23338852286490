/* eslint-disable class-methods-use-this */
import * as React from 'react';
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  DropdownCellTemplate as GridDropdownCellTemplate,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';

import EditModeCellWrap from './EditModeCellWrap';
import { getDropdownSelectedOption, getDropdownText } from '../cell_utils';
import DropdownInput from './DropdownInput';

type CSSObjectWithLabel = any;

// THIS FILE IS A extended from ORIGINAL FILE DropdownCellTemplate.tsx with selection logic fix

export type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export interface DropdownCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'dropdown';
  selectedValue?: string;
  filterText?: string;
  values: OptionType[];
  isDisabled?: boolean;
  isOpen?: boolean;
  inputValue?: string;
  isInvalidCell?: boolean;
  errorMessage?: string;
  styles?: {
    container?: CSSObjectWithLabel;
    control?: CSSObjectWithLabel;
    indicatorsContainer?: CSSObjectWithLabel;
    dropdownIndicator?: CSSObjectWithLabel;
    singleValue?: CSSObjectWithLabel;
    indicatorSeparator?: CSSObjectWithLabel;
    input?: CSSObjectWithLabel;
    valueContainer?: CSSObjectWithLabel;
  };
}

// @ts-ignore
class DropdownCellTemplate extends GridDropdownCellTemplate
  implements CellTemplate<DropdownCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DropdownCell>,
  ): Compatible<DropdownCell> {
    let selectedValue: string | undefined;
    let columnId: string | undefined;
    let rowId: number | undefined;

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }

    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue ? parseFloat(selectedValue) : NaN;

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean');
    } catch {
      isDisabled = false;
    }

    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }

    let isOpen: boolean;
    try {
      isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean');
    } catch {
      isOpen = false;
    }

    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }

    const text = getDropdownText(selectedValue || '', uncertainCell);

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      values,
      isDisabled,
      isOpen,
      columnId,
      rowId,
      inputValue,
    };
  }

  update(
    cell: Compatible<DropdownCell>,
    cellToMerge: UncertainCompatible<DropdownCell>,
  ): Compatible<DropdownCell> {
    const selectedOption = getDropdownSelectedOption(
      cellToMerge.text,
      cell.values,
    );

    return this.getCompatibleCell({
      ...cell,
      selectedValue: selectedOption?.value,
      isOpen: cellToMerge.isOpen,
      inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(cell: Compatible<DropdownCell>, _isInEditMode: boolean): string {
    const cellValue = cell.selectedValue || '';
    const className = cell.className ? cell.className : '';
    const isMissingData = cellValue === '';
    const isValidClass = cell.isInvalidCell
      ? `rg-invalid ${isMissingData ? 'rg-invalid-missing-data' : ''}`
      : 'valid';

    const isOpen = cell.isOpen ? 'open' : 'closed';
    return `${className} ${isOpen} ${isValidClass}`;
  }

  render(
    cell: Compatible<DropdownCell>,
    _isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DropdownCell>, commit: boolean) => void,
  ): React.ReactNode {
    const cellId = `${cell.rowId}-${cell.columnId}`;
    return (
      <EditModeCellWrap
        isInvalidCell={cell.isInvalidCell}
        errorMessage={cell.errorMessage}
        cellValue={cell.selectedValue || ''}
        cellId={cellId}
      >
        <DropdownInput
          onCellChanged={(newCell: Compatible<DropdownCell>) =>
            onCellChanged(this.getCompatibleCell(newCell), true)
          }
          cell={cell}
        />
      </EditModeCellWrap>
    );
  }
}

export default DropdownCellTemplate;
