/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { IconEditTiny, Stack, Text } from '@angellist/adapt';
import LinkSvg from '../../css/icons/Link';
import { compactArray, formatDate, formatNumber } from './utils';
import StatusTag from '../components/common/StatusTag';
import useCountryCodes from '../components/common/hooks/useCountryCodes';
import { getStateNameByCode } from './address/states';
import { StopPropagationEvent } from '../components/common/hoc';
import HealthCheckDot from '../components/health_checks/HealthCheckDot';

const DefaultWrapper = ({ children, ...rest }) => <h5 {...rest}>{children}</h5>;

export const LinkTextColumn = ({
  name,
  Component = DefaultWrapper,
  ...rest
}) => (
  <Component className="mb-0 label-3-table-link cursor-pointer" {...rest}>
    {name}
    <LinkSvg />
  </Component>
);

export const LinkColumn = ({ name, to, ...rest }) => (
  <Link className="table-link" data-name-sort={name} to={to}>
    <LinkTextColumn name={name} {...rest} />
  </Link>
);

export const EmployeeNameColumn = ({ id, name }) => (
  <a className="table-link" data-name-sort={name} href={`/employee/${id}`}>
    <LinkTextColumn name={name} />
  </a>
);

export const linkRowFormatter = (linkName, linkPath) => (
  <Link className="table-link" data-name-sort={linkName} to={linkPath}>
    <LinkTextColumn name={linkName} />
  </Link>
);

export const emphasisTextFormatter = (name) => <Text emphasis>{name}</Text>;

export const NameColumn = (props) => {
  const { isDraft, name, healthChecks } = props;
  const activeChecks =
    healthChecks?.filter((check) => check.status === 'active') || [];
  return (
    <Stack direction="horizontal" gap="25" align="center">
      {isDraft ? (
        <Stack direction="horizontal" gap="25" align="center">
          <Text emphasis color="text70">
            {name}
          </Text>
          <IconEditTiny color="text30" />
        </Stack>
      ) : (
        <Text emphasis>{name}</Text>
      )}
      {!!healthChecks?.length && (
        <StopPropagationEvent>
          <HealthCheckDot healthChecks={activeChecks} />
        </StopPropagationEvent>
      )}
    </Stack>
  );
};

export const dateFormatter = (date, invalidDateText = 'Unknown') => {
  if (!date || date < 15000000) {
    return <span>{invalidDateText}</span>;
  }
  return <span>{formatDate(date)}</span>;
};

export const numberFormatter = (number) => (
  <strong>{formatNumber(number)}</strong>
);

export const boolFormatter = (val) => (val ? 'Yes' : 'No');

export const statusFormatter = (status, intent = null, label = null) => (
  <StatusTag status={status} label={label} intent={intent} />
);

export const emailFormatter = (email) => (
  <a href={`mailto:${email}`}>{email}</a>
);

export const phoneFormatter = (phone) => <a href={`tel:${phone}`}>{phone}</a>;

export const formatAddress = (address) => {
  if (address) {
    return (
      <span>
        {compactArray(address.street, address.city).join(', ')}
        <br />
        {compactArray(address.state, address.country, address.postalCode).join(
          ', ',
        )}
      </span>
    );
  }
  return '—';
};

export const formatAddressWithNames = (address, countryCodeMap) => {
  if (address) {
    return formatAddress({
      ...address,
      state: getStateNameByCode(address.country, address.state),
      country: countryCodeMap[address.country] || address.country,
    });
  }
  return '-';
};

export const formatAddressWithDisplayNames = (address) => {
  const { countryCodeMap } = useCountryCodes();
  return formatAddressWithNames(address, countryCodeMap);
};
