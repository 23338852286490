/* eslint-disable class-methods-use-this */
import * as React from 'react';

import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  HeaderCellTemplate as GridHeaderCellTemplate,
} from '@silevis/reactgrid';
import { Stack, Text, Tooltip } from '@angellist/adapt';

export interface HeaderCell extends Cell, Span {
  type: 'header';
  description?: string | React.ReactNode;
  text: string;
}

// THIS FILE IS is extended from ORIGINAL FILE HeaderCellTemplate.tsx with our custom header logic

class HeaderCellTemplate extends GridHeaderCellTemplate
  implements CellTemplate<HeaderCell> {
  render(cell: Compatible<HeaderCell>): React.ReactNode {
    if (cell.description) {
      return (
        <Stack
          flex="1"
          direction="horizontal"
          align="center"
          justify="space-between"
          gap="50"
        >
          <Text>{cell.text}</Text>
          <Tooltip content={cell.description} icon="infoTiny" />
        </Stack>
      );
    }

    return <Text>{cell.text}</Text>;
  }
}

export default HeaderCellTemplate;
