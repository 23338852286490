import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/react-hooks';

import { showGraphQLError } from '../../../helpers/utils';

import UPDATE_HEALTH_CHECK from '../mutations/UpdateHealthCheck';

const useUpdateHealthCheck = (id: string) => {
  const alert = useAlert();

  const [
    updateHealthCheck,
    { loading: mutationLoading, error: mutationError, called },
  ] = useMutation(UPDATE_HEALTH_CHECK, {
    onCompleted() {
      alert.success('Health check updated successfully');
    },
    onError: ({ graphQLErrors }) => {
      showGraphQLError(alert, graphQLErrors);
    },
  });

  const onUpdate = async (status: string) =>
    updateHealthCheck({
      variables: {
        id,
        status,
      },
    });

  return { submitting: mutationLoading && called, mutationError, onUpdate };
};

export default useUpdateHealthCheck;
