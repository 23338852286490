import gql from 'graphql-tag';

const HEALTH_CHECK_STATUSES_QUERY = gql`
  query HealthCheckStatuses($companyId: ID!) {
    healthCheckStatuses(companyId: $companyId) {
      id
      title
      degree
      category
      checkType
      checkCount
    }
  }
`;

export default HEALTH_CHECK_STATUSES_QUERY;
