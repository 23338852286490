/* eslint-disable class-methods-use-this */
import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  getCellProperty,
  keyCodes,
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCharFromKey,
  inNumericKey,
  isNavigationKey,
  isAllowedOnNumberTypingKey,
  isCharAllowedOnNumberInput,
  NumberCellTemplate as GridNumberCellTemplate,
} from '@silevis/reactgrid';
import EditModeCellWrap from './EditModeCellWrap';
import { getNumberText } from '../cell_utils';
import { MAX_ALLOWED_DECIMALS } from '../constants';

export interface NumberCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'number';
  value: number;
  nanToZero?: boolean;
  hideZero?: boolean;
  isInvalidCell?: boolean;
  errorMessage?: string;
  filterText?: string | number;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  maxLength?: number;
  decimalsLimit?: number;
  prefix?: string;
  suffix?: string;
}

function parseLocaleNumber(stringNumber: string): number {
  if (!stringNumber.trim()) return NaN;
  const thousandsSeparator = ',';
  const decimalSeparator = '.';
  const normalizedStringNumber = stringNumber.replace(/\u00A0/g, ' '); // Replace non-breaking space with normal space
  const numberString = normalizedStringNumber
    .replace(new RegExp(`[${thousandsSeparator}\\s]`, 'g'), '') // Replace thousands separator and white-space
    .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.'); // Replace decimal separator

  const trimmedNumberString = numberString.replace(/^(?!-)\D+|\D+$/g, ''); // Remove characters before first and after last number, but keep negative sign
  if (trimmedNumberString === null || trimmedNumberString.trim().length === 0) {
    return NaN;
  }
  return Number(trimmedNumberString);
}

// THIS FILE IS A extended from ORIGINAL FILE NumberCellTemplate.tsx with our custom validation logic

// @ts-ignore
class NumberCellTemplate extends GridNumberCellTemplate
  implements CellTemplate<NumberCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(
    uncertainCell: Uncertain<NumberCell>,
  ): Compatible<NumberCell> {
    let value: number;
    try {
      value = getCellProperty(uncertainCell, 'value', 'number');
    } catch (error) {
      value = NaN;
    }
    let columnId: string | undefined;
    let rowId: number | undefined;
    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text = getNumberText(displayValue, uncertainCell);
    return { ...uncertainCell, value: displayValue, text, columnId, rowId };
  }

  getClassName(cell: Compatible<NumberCell>, _isInEditMode: boolean): string {
    const className = cell.className ? cell.className : '';
    const isMissingData = !(cell.value && cell.value !== 0);
    const isValidClass = cell.isInvalidCell
      ? `rg-invalid ${isMissingData ? 'rg-invalid-missing-data' : ''}`
      : 'valid';
    return `${isValidClass} ${className}`;
  }

  render(
    cell: Compatible<NumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<NumberCell>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      const cellValue = Number.isNaN(cell.value) ? cell.text : cell.value;
      return (
        <EditModeCellWrap
          isInvalidCell={cell.isInvalidCell}
          errorMessage={cell.errorMessage}
          cellValue={cellValue}
          cellId={`${cell.rowId}-${cell.columnId}`}
        >
          {cell.text}
        </EditModeCellWrap>
      );
    }

    return (
      <NumberFormat
        className="rg-input"
        data-1p-ignore
        getInputRef={(input: any) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.value}
        onValueChange={(values) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: values.floatValue,
            }),
            false,
          );
        }}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseLocaleNumber(e.target.value),
            }),
            !this.wasEscKeyPressed,
          );
          this.wasEscKeyPressed = false;
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          const isPasteKey =
            (e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_V;
          const isSelectAllKey =
            (e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_A;

          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode) ||
            isSelectAllKey
          )
            e.stopPropagation();
          if (
            !inNumericKey(e.keyCode) &&
            !isNavigationKey(e.keyCode) &&
            !isCharAllowedOnNumberInput(getCharFromKey(e.key)) &&
            !isPasteKey
          )
            e.preventDefault();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e: React.ClipboardEvent) => e.stopPropagation()}
        onCut={(e: React.ClipboardEvent) => e.stopPropagation()}
        onPaste={(e: React.ClipboardEvent) => e.stopPropagation()}
        onPointerDown={(e: React.PointerEvent) => e.stopPropagation()}
        maxLength={cell.maxLength}
        allowNegative={cell.allowNegative}
        allowLeadingZeros={cell.allowLeadingZeros}
        decimalScale={cell.decimalsLimit ?? MAX_ALLOWED_DECIMALS}
        prefix={cell.prefix}
        suffix={cell.suffix}
      />
    );
  }
}

export default NumberCellTemplate;
